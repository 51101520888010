import React from "react"

import {Container} from "react-bootstrap"
import {Link} from 'gatsby'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import App from "../components/App"
import Header from "../components/Header"
import Spacer from "../components/Spacer"
import Footer from "../components/Footer"
import SEO from "../components/seo"

export default () => (
  <App>
    <SEO title="Impressum"/>
    <Header />
    <Container id="agb">
      <Spacer variant="large"/>
      <Link className="d-block d-md-none" to="/"><KeyboardBackspaceIcon/> Start</Link>
      <h1 className="d-md-block d-none">Impressum</h1>
      <h3 className="d-block d-md-none">Impressum</h3>
      <section className="col-12">
                <h4>Angaben gemäß § 5 TMG:</h4> 
                <p>Zorrilla Media GbR<br/>
                Im Klinker 7<br/>
                65474 Bischofsheim
                </p>
                <br/>
                <h4>Gesellschafter</h4> 
                <p>Thomas Bruckner<br/>
                Ricardo Ruiz-Zorrilla Morales<br/>
                </p>
                <br/>
                <h4>Umsatzsteuernummer:</h4> 
                <p>DE320425972</p>
                <br/>
                <h4>Steuernummer:</h4> 
                <p>021 386 60459</p>
                <br/>
                <h4>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h4> 
                <p>Thomas Bruckner<br/>
                Ricardo Ruiz-Zorrilla Morales<br/>
                Im Klinker 7<br/>
                65474 Bischofsheim
                </p>
                <br/>
                <h4>Kontaktaufnahme:</h4> 
                <p>Tel: +49 6144 920499910<br/>
                Mail: hello@zorrillamedia.de
                </p>
          </section>
          <Spacer variant="medium"/>
    </Container>
    <section className="bg-dark text-light pt-4">
      <Footer />
    </section>
  </App>
)
